import React from 'react';

import Board from './components/Board';

class App extends React.Component {

  render() {
    return <Board />;
  }
}

export default App;